<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HelloWorld msg="Welcome to Your Vue.js App"/>

    <div v-if="products" class="accordion" role="tablist">
      <b-card id="accordion-1-wrap" no-body class="">
        <b-card-header header-tag="header" class="p-0 d-flex" role="tab">
          <img class="cat-bg" v-bind:src="categories['Beginner']" />
          <div class="rel w-100 text-left" v-b-toggle.accordion-1><b-button block class="cat-title">Beginner</b-button></div>
        </b-card-header>
        <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <ul class="product-list-ul" id="example-1">                
                <li v-for="item in filterByLevelBginner" :key="item.id">
                  <router-link :to="'/product/' + item.id"><img v-if="item.title_image" class="product-image-name" v-bind:src="item.title_image" />
                  <span v-else>{{ item.title }}</span></router-link>
                </li>
              </ul>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card id="accordion-2-wrap" no-body class="">
        <b-card-header header-tag="header" class="p-0 d-flex" role="tab">
          <img class="cat-bg" v-bind:src="categories['Intermediate']" />
          <div class="w-100 rel text-left" v-b-toggle.accordion-2><b-button block class="cat-title">Intermediate</b-button></div>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <ul class="product-list-ul" id="example-1">                
                <li v-for="item in filterByLevelIntermediate" :key="item.id">
                  <router-link :to="'/product/' + item.id"><img v-if="item.title_image" class="product-image-name" v-bind:class="'pim-'+item.id" v-bind:src="item.title_image" />
                  <span v-else>{{ item.title }}</span></router-link>
                </li>
              </ul>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card id="accordion-3-wrap" no-body class="">
        <b-card-header header-tag="header" class="p-0 d-flex" role="tab">
          <img class="cat-bg" v-bind:src="categories['Advanced']" />
          <div class="w-100 rel text-left" v-b-toggle.accordion-3><b-button block class="cat-title">Advanced</b-button></div>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <ul class="product-list-ul" id="example-1">                
                <li v-for="item in filterByLevelAdvanced" :key="item.id">
                  <router-link :to="'/product/' + item.id"><img v-if="item.title_image" class="product-image-name" v-bind:data-id="item.id" v-bind:alt="item.title" v-bind:src="item.title_image" />
                  <span v-else>{{ item.title }}</span></router-link>
                </li>
              </ul>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'



export default {
  name: 'Home',
  props: ['products', 'categories'],
  data(){
    return{
      
    }
  },
  components: {
    HelloWorld
  },
  methods: {
    goto(el) {
      var top = el.offsetTop;
      setTimeout( ()=>{ window.scrollTo(0, top); },350 )      
    }
  },
  mounted(){
    
    this.$root.$on('bv::toggle::collapse', (collapseId) => {

      if( collapseId == 'accordion-1'
        || collapseId == 'accordion-2'
        || collapseId == 'accordion-3' ){
          
          var prev = document.getElementsByClassName('card');
          var target = document.getElementById(`${collapseId}-wrap`);

          Array.from(prev).forEach((el) => {
            if(el.id !== collapseId+"-wrap"){
              el.classList.remove('open');              
              this.goto(target);
            }else{              
              target.classList.toggle('open')
            }
          });

        }

      
    })
  },
  computed: {
      filterByLevelBginner: function(){ return this.products.filter(product => !product.levels.indexOf('Beginner')) },
      filterByLevelIntermediate: function(){ return this.products.filter(product => !product.levels.indexOf('Intermediate')) },
      filterByLevelAdvanced: function(){ return this.products.filter(product => !product.levels.indexOf('Advanced')) }
  }
}
</script>
<style >
html,body,#app{
  height: 100%;
}
</style>
<style lang="scss" scoped>
  .pim-46{
    height: 40px!important;
  }
  .home{
    align-self: stretch;
    flex: 1;
  }
  .card-header{
    background: #000;
    height: calc( 30vh - 35px);
    transition: 0.3s;
    position: relative;
  }
  .rel{
    position:relative;
  }
  .product-list-ul{
    list-style: none;
    padding: 0;
    margin: 5px;
    text-align: left;
    a{ display: block; padding: 10px; }
    img{
      margin-top: 5px;
      height: 35px;
      width: auto;
      &[data-id="52"]{
        height: auto;
        width: auto;
        max-width: 100%;
      }
    }
  }
  .card{
    background: #231f20;
    &.open{
      .card-header{
        //height: 45px;
      }
    }

  } 

  .cat-title{   
    text-transform: uppercase;
    background: transparent;
    color: #da382a;
    font-family: industry, sans-serif;
    font-weight: 700;
    font-style: normal;
    border-radius: 0;
    font-size: clamp(0.925rem, 10vw, 1.4rem); 
    min-width: 210px;
    padding-right: 40px;
    border: none;
    position: relative;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
    &:after {
      content: '';
       background-color: #fff;
      background-image: url(https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/06190034/bg-tex.jpg);
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      -webkit-transform-origin: 100% 0;
      -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
      -webkit-transform: skew(330deg);
      -ms-transform: skew(330deg);
      transform: skew(330deg);
      z-index: -1;
    }
    &:hover{
      color:#da382a;
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
  }
  .cat-bg{
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .w-100{
    width: 100%;
  }
  .text-left{
    text-align: left;
  }
</style>
