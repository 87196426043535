<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark" variant="black" class="px-3">
        <div class="d-flex align-items-center">
          <router-link class="inline" to="/"><b-navbar-brand ><img style="max-width: 70px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/06180341/minelab-logo.png" /><span class="sr-only">Minelab</span></b-navbar-brand></router-link>
          <span v-if="retailer" class="diagonal-break " style="-right: 5px;"></span>
          <img v-if="retailer =='bass'" style="max-width:60px;margin-left: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/06171902/bass-pro-shops-logo-png-transparent-2-2-300x179.png"  />
          <img v-if="retailer =='bcf'" style="max-width:80px;margin-left: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/02/15153011/bcflogo-sm.png"  />
          <img v-if="retailer =='anaconda'" style="max-width:120px;margin-left: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/02/15153012/anaconda-sm.png"  />
          <img v-if="retailer =='scheels'" style="max-width:120px;margin-left: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/02/15153012/Scheels-Logo-sm.png"  />
          <img v-if="retailer =='cabelas'" style="max-width:120px;margin-left: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/03/01020236/cabela-sm.png"  />
        </div>
        
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <div class="inner">
              <router-link @click.native="navClick()" v-for="item in products" :key="item.id" :to="'/product/' + item.id" class="nav-link-item">
                <img v-if="item.title_image" class="product-image-name" v-bind:class="'pim-'+item.id" v-bind:data-id="item.id" v-bind:src="item.title_image" />
                <span v-else>{{ item.title }}</span>
              </router-link>
            </div>
        </b-collapse>
      </b-navbar>
    </div>
    <transition :name="transitionName" mode="out-in">
      <router-view v-if="products" :products="products" :categories="categories" />
    </transition>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'Home',
 data(){
    return{
      products: null,
      categories: null,
      transitionName: 'next',
      retailer: null
    }
  },
  watch:{
    $route (to, from){
      if(to.name == 'Home'){
        this.transitionName = 'prev';
      }else if(to.name == 'Home' && from.name == 'Product'){
        this.transitionName = 'prev';
      }else if(to.name == 'Product' && from.name == 'VR'){
        this.transitionName = 'prev';
      }else{
        this.transitionName = 'next';
      }
    }
  }, 
   beforeRouteUpdate (to, from, next) {
    //const toDepth = to.path.split('/').length
    //const fromDepth = from.path.split('/').length
    //this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    //this.transitionName = 'slide-right';
    console.log(to,from,next)
    next()
   },
  mounted(){
    var host = window.location.hostname;    
    if( host == '192.168.112.129' ) host = 'minlab-app.local';

    if (window.DeviceOrientationEvent && 'ontouchstart' in window) {
        // setup real compass thing, with event.alpha
        document.body.classList.add("can-vr");
    } else {
        // setup some mouse following hack
        document.body.classList.add("can-vr");
    }

    //'https://minlab-app.local/wp-json/custom/products/'
    //var url = `https://api.${host}/wp-json/custom/products/`;
    var url = 'https://api.minelab.velvethammerbranding.com/wp-json/custom/products/';
    axios
      .get(url)
      .then( (response) => {
        this.products = response.data.products;
        this.categories = response.data.cats;
        })
      .catch(error => console.log(error))
      .finally(() => this.loading = false)

      this.retailer = this.$route.query.retailer;
  },
  methods:{
    navClick: function(){
      this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
    }
  }
}
</script>

<style>
  body,html{
    background: #000;
  }
</style>
<style lang="scss">
  .pim-52{ width: 100%; max-width: 220px!important; }
  #app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #000;
    color: #fff;
    min-height: 100vh;
  }

  .f-special{
    font-family: teeshirt, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
  }
  #nav-collapse{
      position: absolute;
      top: 60px;
      background: rgba(0,0,0,0.8);
      width: 80%;
      z-index: 9999;
      .inner{
        padding: 10px;
        text-align: left;
      }
      
      .nav-link-item{
        padding: 10px 15px;
      }
  }
  .nav-link-item{
    display: block;
    img {
          max-width: 210px;
          padding: 2px 0;

    }
  }
    .navbar{
    position: relative;
    z-index: 1;
  }
  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .navbar-expand-lg .navbar-toggler{
    display: block!important;
  }
  #app .navbar-expand-lg .navbar-collapse{
    display: none!important;
    max-height: 100vh;
    overflow: auto;
    &.show{
      display: flex !important;
    }
  }
  .navbar-expand-lg {
      flex-wrap: wrap!important;
      
      //justify-content: initial!important;
  }
  #nav-collapse{
    flex-basis: 100%!important;
  }
  .navbar{
        justify-content: space-between!important;
  }
  .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
  }
  .diagonal-break{
    width: 1px;
    height: 100%;
    display: inline-block;
    background: #fff;
    height: 30px;
    display: inline-block;
    transform: rotate(25deg);
  }
  // .fade-enter-active, .fade-leave-active {
  //     transition:  .5s
  //   }
  // .fade-enter, .fade-leave-active {
  //   opacity: 0;
  //   transform: translateX(-100%);
  // }
.next-enter { opacity: 0; transform: scale3d(1, 1, 1) translate3d(100%, 0, 0); }
.next-enter-to { transform: scale3d(1, 1, 1); }
.next-enter-active,
.next-leave-active { transition: 0.5s; }
.next-leave { transform: scale3d(1, 1, 1); }
.next-leave-to { opacity: 0; transform: scale3d(1, 1, 1) translate3d(-100%, 0, 0); }

// prefixed with "prev" so the prev button easily changes to this block of CSS
.prev-enter { opacity: 0; transform: scale3d(1, 1, 1) translate3d(-100%, 0, 0); }
.prev-enter-to { transform: scale3d(1, 1, 1); }
.prev-enter-active,
.prev-leave-active { transition: 0.5s; }
.prev-leave { transform: scale3d(1, 1, 1); }
.prev-leave-to { opacity: 0; transform: scale3d(1, 1, 1) translate3d(100%, 0, 0); }
</style>
