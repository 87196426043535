<template>
  <div class="hello pb-3 pt-4">
    <img class="img-fluid px-2" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/14204922/gearup.jpg" alt="Gear Up" />    
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
